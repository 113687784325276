import { createQueryKeys } from '@lukemorales/query-key-factory';

export const missionApplicationsMatchesOnDataKeys = createQueryKeys(
  'missionApplicationsMatchesOnData',
  {
    missionApplicationsMatchesOnData: (aids: string[]) => ({
      aids,
    }),
  },
);
