import { MissionRoleId } from './MissionRole';
import { MissionCardObject, MissionId } from './MissionObject';
import { ExperienceId } from './ExperienceObject';
import RoleCategoryObject from './RoleCategory';
import { AdminBasicUserObject, BasicUserObject } from './UserObject';
import { DateISOString } from './misc';
import { WorkingHoursSchemaObject } from './WorkingHoursObject';
import { TalentSkillRating } from './TalentCategories';
import { ProposalId } from './ProposalObject';
import { RecommendationObject } from './RecommendationObject';
import { CompanyId } from './Company';

export type MissionApplicationId = string;

export enum MissionApplicationStatus {
  Created = 'Created',
  Shortlist = 'Shortlist',
}

export enum ApplicationConnectionType {
  Recommendation = 'Recommendation',
  RecommendedBy = 'RecommendedBy',
  Invitation = 'Invitation',
}

export enum MissionMissingRequirement {
  StrictRateMismatch = 'StrictRateMismatch',
  StrictSkillsMismatch = 'StrictSkillsMismatch',
  StrictLocationMismatch = 'StrictLocationMismatch',
  StrictAvailabilityMismatch = 'StrictAvailabilityMismatch',
  StrictWorkingHoursMismatch = 'StrictWorkingHoursMismatch',
  BufferedAvailabilityMismatch = 'BufferedAvailabilityMismatch',
  BufferedWorkingHoursMismatch = 'BufferedWorkingHoursMismatch',
  BufferedSkillsMismatch = 'BufferedSkillsMismatch',
  BufferedRateMismatch = 'BufferedRateMismatch',
}

export interface ApplicationMissingRequirementsResponse {
  missingRequirements: MissionMissingRequirement[];
  overlappedTimeInMinutes?: number;
  missingOrLowProficiencySkills: {
    talentSkillId: string;
    talentSkillName?: string;
    rating?: number;
  }[];
  weeklyHoursAvailable?: number;
}

export interface MissionApplicationsMatchesOnDataRole {
  rid: MissionRoleId;
  roleName: string;
}

export interface MissionApplicationsMatchesOnData {
  aid: MissionApplicationId;
  role: MissionApplicationsMatchesOnDataRole;
  mid: MissionId;
  missionName: string;
  cid: CompanyId;
  companyName: string;
}

export enum ProposedMethod {
  Proposal = 'Proposal', // Proposed automatically via the proposal wizard
  Manual = 'Manual', // Manually marked as proposed
}

export enum ExclusiveStatus {
  Exclusive = 'Exclusive',
  OnHold = 'OnHold',
}

export interface ApplicationConnection {
  user: Partial<AdminBasicUserObject> & { email: string; fullName: string };
  connectionType: ApplicationConnectionType;
  currentMission: boolean;
  date: DateISOString;
}

export interface ApplicationRateRange {
  min: number;
  max: number;
}

export interface CustomQuestionReply {
  qid: string;
  text: string;
}
export interface TeamUpRequestResponse {
  rid: string;
  status: string;
}

export interface MissionApplicationData {
  pitch: string;
  about?: string;
  hourlyRateRange?: ApplicationRateRange;
  monthlyRateRange?: ApplicationRateRange;
  workingHours?: WorkingHoursSchemaObject;
  hiddenProjects?: ExperienceId[];
  proposedRates?: {
    builderHourlyRate?: number;
    clientHourlyRate?: number;
    builderMonthlyRate?: number;
    clientMonthlyRate?: number;
  };
  availability: {
    startDate: DateISOString;
    hoursPerWeek: number;
    notes?: string;
  };
  customQuestionsReplies?: CustomQuestionReply[];
  teamUpRequestResponses?: TeamUpRequestResponse[];
  withdrawn?: boolean;
  changes?: {
    lastReviewAt?: DateISOString;
    pitchUpdatedAt?: DateISOString;
    aboutUpdatedAt?: DateISOString;
    rateUpdatedAt?: DateISOString;
    availabilityUpdatedAt?: DateISOString;
    workingHoursUpdatedAt?: DateISOString;
    noteOnAvailabilityUpdatedAt?: DateISOString;
    lastHourlyRateRange?: ApplicationRateRange;
    customQuestionRepliesUpdatedAt?: {
      [qid: string]: DateISOString;
    };
  };
  analysis?: MissionApplicationAnalysisObject;
  starred?: boolean;
}

export interface MissionApplicationBasicObject {
  aid: MissionApplicationId;
  rid: MissionRoleId;
  status: MissionApplicationStatus;
  withdrawn?: boolean;
  changes?: {
    lastReviewAt?: DateISOString;
    pitchUpdatedAt?: DateISOString;
    aboutUpdatedAt?: DateISOString;
    rateUpdatedAt?: DateISOString;
    availabilityUpdatedAt?: DateISOString;
    workingHoursUpdatedAt?: DateISOString;
    noteOnAvailabilityUpdatedAt?: DateISOString;
    lastHourlyRateRange?: ApplicationRateRange;
    customQuestionRepliesUpdatedAt?: {
      [qid: string]: DateISOString;
    };
  };
}

export enum RecommendationMatchCategory {
  Role = 'role',
  Industry = 'industry',
  Skills = 'skills',
}

export type RecommendationMatch = Record<
  RecommendationMatchCategory,
  { items: string[]; count: number }
>;

export interface RecommendedProject {
  eid: ExperienceId;
  matches: RecommendationMatch;
}

export interface MissionApplicationDraftObject extends MissionApplicationData {
  user: BasicUserObject;
  roleCategory: RoleCategoryObject;
  recommendedProjects?: RecommendedProject[];
}

/** @deprecated-onboarding **/
export type MissionApplicationPreview = Omit<
  MissionApplicationDraftObject,
  'hourlyRate' | 'phoneNumber' | 'pitch' | 'hourlyRateRange'
>;

export interface AnalysisScoreObject {
  overall: number;
  clarity?: number;
  pca?: number;
  experience?: number;
  skills?: number;
  locations?: number;
}
export interface MissionApplicationAnalysisObject {
  id: string;
  evaluation: string;
  suggestedImprovements?: Array<string>;
  scores: AnalysisScoreObject;
  createdAt: Date;
}

export interface MissionApplicationPromptSkill {
  name: string;
  level?: TalentSkillRating;
}

export interface MissionApplicationPromptExperience {
  description: string;
  title: string;
}

export interface MissionApplicationPromptContent {
  builderFirstName: string;
  roleName: string;
  pitch: string;
  aboutMe: string;
  linkedIn: string;
  roleDescription: string;
  projectDescription: string;
  projects?: Array<MissionApplicationPromptExperience>;
  jobs?: Array<MissionApplicationPromptExperience>;
  skills: Array<MissionApplicationPromptSkill>;
  prompt?: string;
}

export default interface MissionApplicationObject
  extends MissionApplicationBasicObject,
    MissionApplicationDraftObject {
  pitch: MissionApplicationData['pitch'];
  about?: MissionApplicationData['about'];
  availability: MissionApplicationData['availability'];
  createdAt: DateISOString;
  updatedAt: DateISOString;
  analysis?: MissionApplicationAnalysisObject;
}

export enum MissionApplicationInternalStatus {
  New = 'New',
  Interviewing = 'Interviewing',
  Rejected = 'Rejected',
  ShortlistGood = 'ShortlistGood',
  ShortlistStrong = 'ShortlistStrong',
  NotAvailable = 'NotAvailable',
  /** @deprecated **/
  ProbablyNot = 'ProbablyNot',
}

export enum MissionApplicationLowCompetitivenessReason {
  Rate = 'Rate',
  Availability = 'Availability',
  Timezone = 'Timezone',
  Skills = 'Skills',
  Location = 'Location',
  BetterFit = 'BetterFit',
  Competitiveness = 'Competitiveness',
  Information = 'Information',
  Portfolio = 'Portfolio',
}

export enum MissionApplicationRejectionReason {
  AfterProposalNotSelectedByTheCompany = 'AfterProposalNotSelectedByTheCompany',
  AfterInterviewNotSelectedByTheCompany = 'AfterInterviewNotSelectedByTheCompany',
}

export enum MissionApplicationStatusStage {
  New = 'New',
  Interviewing = 'Interviewing',
  Proposed = 'Proposed',
  Rejected = 'Rejected',
  ShortlistGood = 'ShortlistGood',
  ShortlistStrong = 'ShortlistStrong',
  VettingScheduled = 'VettingScheduled',
  NotAvailable = 'NotAvailable',
  Exclusive = 'Exclusive',
  OnHold = 'OnHold',
  Accepted = 'Accepted',
  /** @deprecated **/
  ProbablyNot = 'ProbablyNot',
  OpportunityToUpdate = 'OpportunityToUpdate',
}

export enum MissionApplicationLifecycleStage {
  Active = 'Active',
  LowChance = 'LowChance',
  Concluded = 'Concluded',
}

export type MissionApplicationBadgeStatus =
  | MissionApplicationStatusStage
  | CurrentUserMissionApplicationStatus.Interviewing
  | CurrentUserMissionApplicationStatus.LowCompetitiveness;

export const MissionApplicationLifecycleStageLabels: Record<
  MissionApplicationLifecycleStage,
  string
> = {
  [MissionApplicationLifecycleStage.Active]: 'Active',
  [MissionApplicationLifecycleStage.LowChance]: 'Low chance',
  [MissionApplicationLifecycleStage.Concluded]: 'Concluded',
};

export const MissionApplicationLowCompetitivenessReasonLabels: Record<
  MissionApplicationLowCompetitivenessReason,
  string
> = {
  [MissionApplicationLowCompetitivenessReason.Rate]: 'Rate too high',
  [MissionApplicationLowCompetitivenessReason.Availability]:
    'Availability not a fit',
  [MissionApplicationLowCompetitivenessReason.Timezone]:
    'Timezone overlap not a fit',
  [MissionApplicationLowCompetitivenessReason.Skills]:
    'Skills or Expertise not a fit',
  [MissionApplicationLowCompetitivenessReason.Location]:
    'Location requirement not met',
  [MissionApplicationLowCompetitivenessReason.BetterFit]:
    'Other better fit candidates',
  [MissionApplicationLowCompetitivenessReason.Competitiveness]:
    'Low Competitiveness',
  [MissionApplicationLowCompetitivenessReason.Information]:
    'Not enough information',
  [MissionApplicationLowCompetitivenessReason.Portfolio]: 'Missing Portfolio',
};

export const MissionApplicationRejectionReasonLabels: Record<
  MissionApplicationRejectionReason,
  string
> = {
  [MissionApplicationRejectionReason.AfterProposalNotSelectedByTheCompany]:
    'After proposal - not selected by the company',
  [MissionApplicationRejectionReason.AfterInterviewNotSelectedByTheCompany]:
    'After interview - not selected by the company',
};

export const MissionApplicationLowCompetitivenessReasonDescriptions: Record<
  MissionApplicationLowCompetitivenessReason,
  string
> = {
  [MissionApplicationLowCompetitivenessReason.Rate]:
    'At your current rate you are not competitive with the company requirements and the proposals of other builders. You can edit your rate and contact the Team Architect to be reconsidered.',
  [MissionApplicationLowCompetitivenessReason.Availability]:
    'Your availability does not meet company requirements. You can edit your application and contact the Team Architect to be reconsidered.',
  [MissionApplicationLowCompetitivenessReason.Timezone]:
    'Your working hour overlap does not meet company requirements. You can edit your application and contact the Team Architect to be reconsidered.',
  [MissionApplicationLowCompetitivenessReason.Skills]:
    'Your skill level in the position’s required skills does not meet company requirements. You can edit your profile and contact the Team Architect to be reconsidered.',
  [MissionApplicationLowCompetitivenessReason.Location]:
    'Your location does not meet the company’s requirements for this position. You can edit your profile and contact the Team Architect to be reconsidered.',
  [MissionApplicationLowCompetitivenessReason.BetterFit]:
    'Other builders who requested to join this mission were considered to be a better fit.',
  [MissionApplicationLowCompetitivenessReason.Competitiveness]:
    'Considering the company requirements and the proposals from other builders, it is unlikely you will be proposed to the company.',
  [MissionApplicationLowCompetitivenessReason.Information]:
    'We don’t have enough information to determine your mission- and team fit. Please add more details to your profile (projects, jobs, skills, etc) and contact the Team Architect to be reconsidered.',
  [MissionApplicationLowCompetitivenessReason.Portfolio]:
    'You haven’t yet uploaded a portfolio with case studies or haven’t provided the password to access it - which means we can’t determine your mission & team fit. Please add your portfolio link to your profile to be reconsidered.',
};

export const MissionApplicationInternalStatusLabels: Record<
  MissionApplicationStatusStage,
  string
> = {
  [MissionApplicationStatusStage.New]: 'New',
  [MissionApplicationStatusStage.Interviewing]: 'Interviewing',
  [MissionApplicationStatusStage.Rejected]: 'Rejected',
  [MissionApplicationStatusStage.ShortlistGood]: 'Shortlist - Good',
  [MissionApplicationStatusStage.ShortlistStrong]: 'Shortlist - Strong',
  [MissionApplicationStatusStage.NotAvailable]: 'Not Available',
  [MissionApplicationStatusStage.ProbablyNot]: 'Probably not',
  [MissionApplicationStatusStage.Proposed]: 'Proposed',
  [MissionApplicationStatusStage.VettingScheduled]: 'Vetting Scheduled',
  [MissionApplicationStatusStage.Exclusive]: 'Exclusive',
  [MissionApplicationStatusStage.OnHold]: 'On Hold',
  [MissionApplicationStatusStage.Accepted]: 'Accepted',
  [MissionApplicationStatusStage.OpportunityToUpdate]: 'Opportunity to update',
};

export enum MissionApplicationReviewStatusNotSelected {
  OverallWeakCandidate = 'OverallWeakCandidate',
  SkillsMismatch = 'SkillsMismatch',
  LocationMismatch = 'LocationMismatch',
  AvailabilityMismatch = 'AvailabilityMismatch',
  ExperienceMismatch = 'ExperienceMismatch',
  WorkingHoursMismatch = 'WorkingHoursMismatch',
  RateMismatch = 'RateMismatch',
  AfterProposal = 'AfterProposal',
  AfterInterview = 'AfterInterview',
}

export enum MissionApplicationReviewStatusOpportunityToUpdate {
  MissingInformation = 'MissingInformation',
  RateNotIdeal = 'RateNotIdeal',
  WorkingHoursNotIdeal = 'WorkingHoursNotIdeal',
}

export enum MissionApplicationReviewStatusWaitlisted {
  WaitlistedGood = 'WaitlistedGood',
  WaitlistedStrong = 'WaitlistedStrong',
}

export enum MissionApplicationReviewStatusOther {
  Unavailable = 'Unavailable',
  Exclusive = 'Exclusive',
  InterviewingFromFormation = 'InterviewingFromFormation',
  PresentedToClient = 'PresentedToClient',
}

export interface MissionApplicationReviewStatus {
  notSelected?: MissionApplicationReviewStatusNotSelected[];
  opportunityToUpdate?: MissionApplicationReviewStatusOpportunityToUpdate[];
  waitlisted?: MissionApplicationReviewStatusWaitlisted[];
  other?: MissionApplicationReviewStatusOther[];
}

export const MissionApplicationReviewStatusNotSelectedLabels: Record<
  MissionApplicationReviewStatusNotSelected,
  string
> = {
  [MissionApplicationReviewStatusNotSelected.OverallWeakCandidate]:
    'Not selected',
  [MissionApplicationReviewStatusNotSelected.SkillsMismatch]: 'Skills mismatch',
  [MissionApplicationReviewStatusNotSelected.LocationMismatch]:
    'Location mismatch',
  [MissionApplicationReviewStatusNotSelected.AvailabilityMismatch]:
    'Availability mismatch',
  [MissionApplicationReviewStatusNotSelected.ExperienceMismatch]:
    'Experience mismatch',
  [MissionApplicationReviewStatusNotSelected.WorkingHoursMismatch]:
    'Working hours mismatch',
  [MissionApplicationReviewStatusNotSelected.RateMismatch]: 'Rate mismatch',
  [MissionApplicationReviewStatusNotSelected.AfterProposal]:
    'After proposal - not selected',
  [MissionApplicationReviewStatusNotSelected.AfterInterview]:
    'After interview - not selected',
};

export const MissionApplicationReviewStatusOpportunityToUpdateLabels: Record<
  MissionApplicationReviewStatusOpportunityToUpdate,
  string
> = {
  [MissionApplicationReviewStatusOpportunityToUpdate.MissingInformation]:
    'Missing information',
  [MissionApplicationReviewStatusOpportunityToUpdate.RateNotIdeal]:
    'Rate not ideal',
  [MissionApplicationReviewStatusOpportunityToUpdate.WorkingHoursNotIdeal]:
    'Working hours not ideal',
};

export const MissionApplicationReviewStatusWaitlistedLabels: Record<
  MissionApplicationReviewStatusWaitlisted,
  string
> = {
  [MissionApplicationReviewStatusWaitlisted.WaitlistedGood]: 'Waitlist',
  [MissionApplicationReviewStatusWaitlisted.WaitlistedStrong]: 'Shortlist',
};

export const MissionApplicationReviewStatusOtherLabels: Record<
  MissionApplicationReviewStatusOther,
  string
> = {
  [MissionApplicationReviewStatusOther.Unavailable]: 'Unavailable',
  [MissionApplicationReviewStatusOther.Exclusive]: 'Exclusive',
  [MissionApplicationReviewStatusOther.InterviewingFromFormation]:
    'Interviewing (Manual)',
  [MissionApplicationReviewStatusOther.PresentedToClient]: 'Proposed - Shared',
};

export interface ApplicantStatus {
  reviewStatus?: MissionApplicationReviewStatus;
  vettingScheduled?: boolean;
  accepted?: boolean;
  exclusiveStatus?: ExclusiveStatus;
  proposal?: ProposalId;
  proposalManuallySharedAt?: DateISOString;
  proposedRates?: MissionApplicationData['proposedRates'];
  proposalInterviewing?: boolean;

  /** @deprecated **/
  internalStatus: MissionApplicationInternalStatus;
  /** @deprecated **/
  lowCompetitiveness?: MissionApplicationLowCompetitivenessReason[];
  /** @deprecated **/
  rejectionReason?: MissionApplicationRejectionReason[];
  /** @deprecated **/
  proposedToClient?: ProposedMethod;
}

export interface MissingRequirements {
  missingRequirements?: MissionMissingRequirement[];
}

export interface AdminMissionApplicationObject
  extends MissionApplicationObject,
    ApplicantStatus,
    MissingRequirements {
  user: AdminBasicUserObject;
}

export enum CurrentUserMissionApplicationStatus {
  New = 'New',
  Shortlist = 'Shortlist',
  Proposed = 'Proposed',
  Interviewing = 'Interviewing',
  Rejected = 'Rejected',
  NotAvailable = 'NotAvailable',
  RoleCancelled = 'RoleCancelled',
  RoleEnded = 'RoleEnded',
  MissionCancelled = 'MissionCancelled',
  MissionEnded = 'MissionEnded',
  Accepted = 'Accepted',
  LowCompetitiveness = 'LowCompetitiveness',
  ConfirmedAnotherBuilder = 'ConfirmedAnotherBuilder',
  OpportunityToUpdate = 'OpportunityToUpdate',
}

export const CurrentUserMissionApplicationStatusLabels: Record<
  CurrentUserMissionApplicationStatus,
  string
> = {
  [CurrentUserMissionApplicationStatus.New]: 'Applied',
  [CurrentUserMissionApplicationStatus.Shortlist]: 'Shortlist',
  [CurrentUserMissionApplicationStatus.Proposed]: 'Proposed',
  [CurrentUserMissionApplicationStatus.Interviewing]: 'Interviewing',
  [CurrentUserMissionApplicationStatus.NotAvailable]: 'Unavailable',
  [CurrentUserMissionApplicationStatus.Rejected]: 'Not selected',
  [CurrentUserMissionApplicationStatus.RoleCancelled]: 'Role cancelled',
  [CurrentUserMissionApplicationStatus.RoleEnded]: 'Role ended',
  [CurrentUserMissionApplicationStatus.MissionCancelled]: 'Mission cancelled',
  [CurrentUserMissionApplicationStatus.MissionEnded]: 'Mission ended',
  [CurrentUserMissionApplicationStatus.Accepted]: 'Accepted',
  [CurrentUserMissionApplicationStatus.LowCompetitiveness]:
    'Low Competitiveness',
  [CurrentUserMissionApplicationStatus.ConfirmedAnotherBuilder]:
    'Confirmed Another Builder',
  [CurrentUserMissionApplicationStatus.OpportunityToUpdate]:
    'Opportunity to update',
};

export interface CurrentUserMissionApplicationObject
  extends Omit<MissionApplicationObject, 'status'> {
  status: CurrentUserMissionApplicationStatus;
  lifecycleStage: MissionApplicationLifecycleStage;
  lowCompetitiveness?: MissionApplicationLowCompetitivenessReason[];
  canWithdraw?: boolean;
  reviewStatus?: MissionApplicationReviewStatus;
  lastReviewAt?: DateISOString;
}

export interface AppliedMissionCardObject {
  mission: MissionCardObject;
  application: CurrentUserMissionApplicationObject;
  userRecommendations?: RecommendationObject[];
}

export enum AutomatedStatusChangeReason {
  NOT_LOOKING_FOR_APPLICATIONS = 'NOT_LOOKING_FOR_APPLICATIONS',
  NOT_INCLUDED_IN_PROPOSAL = 'NOT_INCLUDED_IN_PROPOSAL',
}

export interface MissionApplicationReviewStatusChange {
  reviewStatus?: MissionApplicationReviewStatus;
  automatedStatusChangeReasons?: AutomatedStatusChangeReason[];
  updatedStatusAt: Date;
}

export interface MissionApplicationWithAutomatedStatus {
  missionName?: string;
  missionId?: string;
  missionOwnerName?: string;
  roleName?: string;
  username?: string;
  applicationId?: string;
  automatedReviewStatusChanges?: MissionApplicationReviewStatusChange[];
  lastAutomatedReviewStatusChange?: MissionApplicationReviewStatusChange;
}

export interface PitchPromptUserExperience {
  title: string;
  description: string;
  company: string;
}

export interface CustomQuestionWithAnswer {
  question: string;
  answer: string;
}

export interface MissionApplicationPitchRequest {
  skills: string[];
  projects: string[];
  questionResponses?: CustomQuestionWithAnswer[];
  pitch?: string;
}

export interface MissionApplicationPitchPromptData {
  companyName?: string;
  companyStory?: string;
  industries?: string;
  missionSkills: string[];
  missionDescription: string;
  roleDescription: string;
  aboutMe: string;
  mainRole?: string;
  userSkills: string[];
  existingPitch?: string;
  userLastPitches?: string[];
  customQuestionResponses?: CustomQuestionWithAnswer[];
  projects: PitchPromptUserExperience[];
  jobs?: PitchPromptUserExperience[];
}

export interface CustomQuestionRecommendationRequest {
  customSkills: string[];
  questionId: string;
}

export interface CustomQuestionRecommendationPromptData {
  companyName?: string;
  companyStory?: string;
  missionDescription: string;
  roleDescription: string;
  aboutMe: string;
  mainRole: string;
  userSkills: string[];
  question: string;
  projects: PitchPromptUserExperience[];
  jobs?: PitchPromptUserExperience[];
}
