import React, { useState } from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import ConfirmSubmissionModal from '@src/views/Profile/Main/ConfirmSubmissionModal';
import { useHistory } from 'react-router-dom';
import { useStores } from '@src/stores';
import { useConfirmUpdatesSubmission } from '@src/rq/users';

interface ReviseProfileStepProps {
  onboardingStage: OnboardingStage;
}

const ReviseProfileStep = ({ onboardingStage }: ReviseProfileStepProps) => {
  const history = useHistory();
  const { auth } = useStores();
  const { mutateAsync: confirmUpdatesSubmission, isLoading } =
    useConfirmUpdatesSubmission();

  const [showConfirmSubmitEdits, setShowConfirmSubmitEdits] = useState(false);

  if (
    onboardingStage !== OnboardingStage.PendingSecondReview &&
    onboardingStage !== OnboardingStage.SecondReviewRequested
  ) {
    return null;
  }

  const handleUpdateProfile = () => {
    if (auth.username) {
      history.push(auth.username);
    }
  };

  const handlerConfirmSubmitEdits = async () => {
    await confirmUpdatesSubmission();
    setShowConfirmSubmitEdits(false);
  };

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value="3" />,
    title: 'Complete and resubmit your profile',
    description:
      'Update your profile with the requested information at the top of the profile page and resubmit for review.',
    cta: (
      <>
        <Button size="md" onClick={handleUpdateProfile}>
          Update profile
        </Button>
        <Button
          size="md"
          onClick={() => setShowConfirmSubmitEdits(true)}
          variant="secondary"
        >
          Resubmit
        </Button>
      </>
    ),
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const IN_PROGRESS_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.IN_PROGRESS} />,
    title: 'You’ve resubmitted your profile for review',
    description:
      'A member of our team will review your profile and reach out via email once complete.',
    cta: <div>In review</div>,
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const stepPayload: SetupProps =
    onboardingStage === OnboardingStage.PendingSecondReview
      ? INCOMPLETE_DATA
      : IN_PROGRESS_DATA;

  const renderConfirmationModal = () => {
    if (!showConfirmSubmitEdits) {
      return null;
    }

    return (
      <ConfirmSubmissionModal
        open={showConfirmSubmitEdits}
        onClose={() => setShowConfirmSubmitEdits(false)}
        onConfirm={handlerConfirmSubmitEdits}
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      <Step {...stepPayload} />
      {renderConfirmationModal()}
    </>
  );
};

export default observer(ReviseProfileStep);
