import { UserId } from '@a_team/models/dist/UserObject';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { apiUser, apiUsers } from '@ateams/api';
import { useStores } from '@src/stores';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';

export const useEvaluateFeatureFlag = ({
  uid,
  featureFlag,
}: {
  uid: UserId;
  featureFlag: FeatureFlagNames;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.users.evaluateFeatureFlag(uid, featureFlag),
    queryFn: async () => {
      return await apiUsers.evaluateFeatureFlag(auth, uid, featureFlag);
    },
  });
};

export const useRequestProfileReview = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.requestProfileReview(stores.auth);
    },
    onSuccess(data) {
      if (data.onboardingStage) {
        stores.auth.updateOnboardingStage(data.onboardingStage);
      }
    },
  });
};

export const useRequestEvaluationCall = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.requestEvaluationCall(stores.auth);
    },
    onSuccess(data) {
      if (data.evaluationFormUrl) {
        window.location.href = data.evaluationFormUrl;
      }
    },
  });
};

export const useGetEvaluationCallDetails = () => {
  const stores = useStores();

  return useQuery({
    queryFn: async () => {
      return apiUser.getEvaluationCallDetails(stores.auth);
    },
  });
};

export const useConfirmUpdatesSubmission = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async () => {
      return await apiUser.confirmUpdatesSubmission(stores.auth);
    },
    onSuccess(data) {
      if (data?.onboardingStage) {
        stores.auth.updateOnboardingStage(data.onboardingStage);
      }
    },
  });
};

export const useMarkOnboardingCompleted = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.markOnboardingCompleted(stores.auth);
    },
    onSuccess(data) {
      if (data.onboardingStage) {
        stores.auth.updateOnboardingStage(data.onboardingStage);
        stores.auth.renewToken();
      }
    },
  });
};

export const useDismissWelcomePage = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiUser.dismissWelcomePage(auth);
    },
    onSuccess: async () => {
      auth.dismissWelcomePage();
    },
  });
};
