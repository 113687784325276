import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import useClickOutside from '@src/hooks/useClickOutside';
import { useStores } from '@src/stores';
import { createUseStyles } from 'react-jss';
import Navbar from './Navbar';
import { observer } from 'mobx-react';
import { Breakpoints, TextColors } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import MobileTopBar from '@src/layouts/Main/MobileTopBar';
import MakerToSModal from '@src/components/Modal/ToSModal';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { UserType } from '@a_team/models/dist/UserObject';
import {
  ClientTermsOfServiceLocation,
  PrivacyPolicyLocation,
  TalentPrivacyStatementLocation,
  TermsOfServiceLocation,
  TokensLocation,
} from '@src/locations';
import { ClientToSModal } from '@src/components/Modal/ToSModal/ClientToSModal';
import TokenIntroductionModal from '@src/components/Modal/TokenIntroductionModal';
import StickyStrip from '@src/components/StickyStrip';
import TextButton from '@src/components/TextButton';
import { ClientAppLocation } from '@src/locations';
import CodeOfConductModal from '@src/components/Modal/CodeOfConductModal';
import NotificationCenter from '@src/components/NotificationSlider';
import ReleaseTeamUpModal from '@src/components/Modal/ReleaseTeamUpModal';
import ReleaseStepsModal from '@src/components/Modal/ReleaseStepsModalTeamUp';
import ReleaseConnectionsBanner from '@src/components/ReleaseConnectionsBanner';

export interface MainLayoutProps {
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  childrenClassName?: string;
  bgColor?: string | null;
  title: string | undefined;
  children: ReactNode;
  hero?: ReactNode;
  sidebar?: ReactNode;
  topNav?: ReactNode;
  naked?: boolean;
  style?: CSSProperties;
  hideNavbar?: boolean;
}

const useStyles = createUseStyles<{
  isAuth: boolean;
}>({
  '@global': {
    body: {
      backgroundColor: '#6d00d7',
      color: '#fff',
    },
  },
  root: {
    paddingLeft: 0,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    minHeight: '100vh',
    paddingBottom: 48,
  },
  children: {
    backgroundColor: '#FFFFFF',
    flexGrow: 1,
    color: '#222',
    width: '100%',

    '&.naked': {
      background: 'none',
    },
  },
  content: {
    marginTop: 32,
    padding: '24px 24px 48px 24px',
  },
  termsFooter: {
    height: 50,
    textAlign: 'center',
    padding: '15px 40px',
    fontSize: 12,
    color: TextColors.regularLight,
    '& .link': {
      color: TextColors.primaryLight,
      cursor: 'pointer',
    },
    marginTop: '-60px',
    position: 'relative',
    '&.naked': {
      color: TextColors.primary,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      paddingLeft: 120,
    },
    content: {
      marginTop: 0,
    },
    children: {
      borderLeft: '1px solid #DADADC',
    },
    container: {
      flexWrap: 'nowrap',
      paddingBottom: 0,
    },
  },
});

function MainLayout(props: MainLayoutProps): ReactElement {
  const {
    title,
    className,
    containerClassName,
    contentClassName,
    childrenClassName,
    children,
    bgColor,
    hero,
    sidebar,
    topNav,
    naked,
    style,
    hideNavbar,
  } = props;

  const { document, auth, rewards, uiStore } = useStores();
  const styles = useStyles();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useToggle(false);
  const [TOSModal, setTOSModal] = useState(auth.user?.needsAcceptTOS === true);
  const [isCodeOfConductModalOpen, setIsCodeOfConductModalOpen] =
    useToggle(false);
  const [isWhatsNewModalOpen, setIsWhatsNewModalOpen] = useToggle(false);
  const [isReleaseStepsModalOpen, setIsReleaseStepsModalOpen] =
    useToggle(false);

  const [showClientAppBanner, setShowClientAppBanner] = useState(
    auth.showClientAppBanner,
  );

  const [isReleaseBannerOpen, setIsReleaseBannerOpen] = useState(false);

  const [loading, setLoading] = useLoadingState();
  const menuRef = useRef(null);
  const hideTOSModal =
    location.pathname === ClientTermsOfServiceLocation ||
    location.pathname === PrivacyPolicyLocation ||
    location.pathname === TermsOfServiceLocation ||
    location.pathname === TalentPrivacyStatementLocation;

  const showTokenModal = location.pathname === TokensLocation;

  useEffect(() => {
    auth.showClientAppBanner !== showClientAppBanner &&
      auth.showClientAppBanner !== showClientAppBanner &&
      setShowClientAppBanner(auth.showClientAppBanner);
  }, [auth.showClientAppBanner]);

  useClickOutside(menuRef, (): void => {
    if (!menuOpen) return;
    setMenuOpen(false);
  });

  useEffect((): (() => void) => {
    const { style } = window.document.body;
    let finalBgColor = '';
    if (bgColor) {
      finalBgColor = bgColor;
    } else if (auth.uid) {
      finalBgColor = '#FFFFFF';
    }

    style.backgroundColor = finalBgColor;

    return (): void => {
      style.backgroundColor = '';
    };
  }, [auth.uid, bgColor]);

  useEffect(() => {
    if (auth.user?.needsAcceptTOS === true) {
      setTOSModal(true);
    }
  }, [auth.user]);

  useEffect(() => {
    if (
      auth.user?.needsAcceptPlatformCodeOfConduct &&
      auth.basicAccess &&
      auth.userType === UserType.User
    ) {
      setIsCodeOfConductModalOpen();
    }
  }, [
    auth.user?.needsAcceptPlatformCodeOfConduct,
    auth.basicAccess,
    auth.userType,
  ]);

  useEffect(() => {
    if (
      !auth.user?.needsAcceptPlatformCodeOfConduct &&
      auth.withWhatsNew &&
      auth.user?.needsWhatsNewModal &&
      auth.user?.activatedAt &&
      auth.basicAccess &&
      auth.verified &&
      auth.userType === UserType.User
    ) {
      setIsWhatsNewModalOpen();
    }
  }, [
    auth.user?.needsAcceptPlatformCodeOfConduct,
    auth.withWhatsNew,
    auth.user?.needsWhatsNewModal,
    auth.user?.activatedAt,
    auth.basicAccess,
    auth.userType,
  ]);

  /*useEffect(() => {
    if (
      !auth.user?.needsAcceptPlatformCodeOfConduct &&
      auth.withWhatsNewBanner &&
      auth.user?.needsWhatsNewBanner &&
      auth.user?.activatedAt &&
      auth.basicAccess &&
      auth.verified &&
      auth.userType === UserType.User
    ) {
      setIsReleaseBannerOpen(true);
    }
  }, [
    !auth.user?.needsAcceptPlatformCodeOfConduct,
    auth.withWhatsNewBanner,
    auth.user?.needsWhatsNewBanner,
    auth.user?.activatedAt,
    auth.basicAccess,
    auth.userType,
  ]);*/

  document.setInnerTitle(title || 'Loading...');

  const acceptToS = () => {
    setLoading(auth.acceptToS(auth).then(() => setTOSModal(false)));
  };

  return (
    <div className={cx(!hideNavbar && styles.root, className)}>
      {showClientAppBanner && (
        <StickyStrip
          onClose={() => {
            auth.setClientAppBannerClosed();
            setShowClientAppBanner(false);
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <strong>🎉&nbsp;&nbsp;We’re moving!</strong>&nbsp; Support for
            platform.a.team will be discontinued after January 31, 2023. To
            visit the new website, go to{' '}
            <TextButton
              highlight
              onClick={() => window.open(ClientAppLocation, '_self')}
            >
              client.a.team
            </TextButton>
          </div>
        </StickyStrip>
      )}
      {isCodeOfConductModalOpen && (
        <CodeOfConductModal
          showOnly="platform"
          hideExit
          isOpen={isCodeOfConductModalOpen}
          toggleModal={setIsCodeOfConductModalOpen}
        />
      )}
      <ReleaseTeamUpModal
        setIsReleaseStepsModalOpen={setIsReleaseStepsModalOpen}
        shouldSetWhatsNewModalAsDisplayed
        isOpen={!isCodeOfConductModalOpen && isWhatsNewModalOpen}
        onClose={setIsWhatsNewModalOpen}
      />
      <ReleaseStepsModal
        open={isReleaseStepsModalOpen}
        onClose={setIsReleaseStepsModalOpen}
        setIsWhatsNewModalOpen={setIsWhatsNewModalOpen}
      />
      {showTokenModal && (
        <TokenIntroductionModal
          show={rewards.showTokenIntroModal}
          onDismiss={rewards.dismissTokenIntroModal.bind(rewards)}
        />
      )}
      {auth.userType === UserType.CompanyUser ? (
        <ClientToSModal
          clientName={auth.user?.fullName || ''}
          onClose={() => {
            return;
          }}
          open={TOSModal && !hideTOSModal}
          onAccept={acceptToS}
        />
      ) : (
        <MakerToSModal
          open={TOSModal && !hideTOSModal}
          onClose={() => {
            return;
          }}
          onAccept={acceptToS}
        />
      )}
      {!hideNavbar && (
        <div ref={menuRef}>
          <MobileTopBar setMenuOpen={auth.user ? setMenuOpen : undefined} />
          <Navbar
            currentUser={auth.user}
            open={menuOpen}
            toggleMenu={setMenuOpen}
          />
        </div>
      )}

      <NotificationCenter
        isOpen={uiStore.notificationSlider}
        toggle={uiStore.toggleNotificationSlider}
      />

      {auth.withWhatsNewBanner && isReleaseBannerOpen && (
        <ReleaseConnectionsBanner
          onClose={() => setIsReleaseBannerOpen(false)}
        />
      )}

      {hero}
      <div className={cx(styles.container, containerClassName)}>
        {sidebar}
        <div className={cx(styles.children, { naked }, childrenClassName)}>
          {topNav}
          {naked ? (
            children
          ) : (
            <div
              className={cx(styles.content, contentClassName)}
              style={{ ...style }}
            >
              {children}
            </div>
          )}
        </div>
      </div>
      <div className={cx(styles.termsFooter, { naked })}>
        &copy;{new Date().getFullYear()} ATeams Inc., All rights reserved.&nbsp;
        <br />
        <span className="link">
          <Link to={TermsOfServiceLocation}>Terms of Service</Link> |{' '}
          <Link to={PrivacyPolicyLocation}>Privacy Policy</Link>
        </span>
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
}

export default observer(MainLayout);
