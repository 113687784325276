import { apiMissions } from '@ateams/api';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import AuthStore from '@src/stores/Auth';
import {
  MissionApplicationId,
  MissionApplicationsMatchesOnData,
} from '@a_team/models/dist/MissionApplicationObject';

const fetchMissionApplicationMatchesOnData =
  (auth: AuthStore, aids: MissionApplicationId[]) =>
  async (): Promise<MissionApplicationsMatchesOnData[]> => {
    return aids.length > 0
      ? apiMissions.getMissionApplicationsMatchesOnData(auth, aids)
      : [];
  };

export const useGetMissionApplicationMatchesOnData = (
  auth: AuthStore,
  aids: MissionApplicationId[],
) => {
  return useQuery(
    queryKeys.missionApplicationsMatchesOnData.missionApplicationsMatchesOnData(
      aids,
    ),
    fetchMissionApplicationMatchesOnData(auth, aids),
  );
};
