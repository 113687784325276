import { Breakpoints } from '@ateams/components';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Step, { StepStatus } from '../Steps/common/Step';
import StepIcon, { StepIconType } from '../Steps/common/Step/StepIcon';
import { Button } from '@a_team/ui-components';
import MainLayout from '@src/layouts/Main';
import Header from './../Header';
import { useDismissWelcomePage } from '@src/rq/users';
import { useHistory } from 'react-router-dom';
import { MissionControlRecommendedLocation } from '@src/locations';

export const useStyles = createUseStyles({
  container: {
    maxWidth: 1240,
    margin: '0 auto',
    padding: '16px 0',
  },
  stepsContainer: {
    margin: '40px auto 0',
    maxWidth: 680,
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
  },
  disclaimer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: '#818388',
    gap: 16,
    fontSize: 15,
    marginTop: 28,
    '& button': {
      width: 138,
      height: 44,
      fontSize: 14,
      borderColor: '#DADADC !important',
    },
  },
  defaultStep: {
    backgroundColor: '#F9FAFC',
    padding: 24,
    borderRadius: 16,
  },
  stepHighlighted: {
    backgroundColor: '#FCFAFF',
    padding: '34px 16px 16px 16px',
    borderRadius: '0 0 16px 16px',
  },
  link: {
    color: '#6D00D7',
    fontSize: 15,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stepsContainer: {
      gap: 8,
      marginBottom: 40,
    },
    disclaimer: {
      flexDirection: 'row',
    },
  },
});

const Welcome = () => {
  const styles = useStyles();
  const history = useHistory();

  const { mutate: dismissWelcomePage } = useDismissWelcomePage();

  const applyToMissionHandler = () => {
    history.push(MissionControlRecommendedLocation);
  };

  const joinMissionStepPayload = {
    stepIcon: <StepIcon type={StepIconType.WEBSITE} />,
    title: 'Join your first mission on A.Team',
    description:
      'Get curated mission recommendations for roles tailored to your skillset and experience in the missions tab.',
    cta: (
      <Button size="md" onClick={applyToMissionHandler}>
        Apply to mission
      </Button>
    ),
    stepStatus: StepStatus.INFO,
    className: styles.stepHighlighted,
  };

  const tipsStepPayload = {
    stepIcon: <StepIcon type={StepIconType.DETAILS} />,
    title: 'Some tips to get started at A.Team',
    description:
      'Explore the A.Guide to hit the ground running and learn how to apply, team up and get selected for missions.',
    cta: (
      <a
        href="https://guide.a.team/"
        target="_blank"
        className={styles.link}
        rel="noreferrer"
      >
        Open A.Guide
      </a>
    ),
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  const dismissWelcomePageHandler = async () => {
    await dismissWelcomePage();

    history.push(MissionControlRecommendedLocation);
  };

  return (
    <MainLayout title="Welcome" style={{ padding: '15px 24px' }}>
      <div className={styles.container}>
        <Header
          title="Welcome to A.Team!"
          subtitle="Congratulations on joining the network. Get ready to team up with top product builders on high-impact, high-paying missions — all on your terms."
          videoUrl="https://www.loom.com/share/ee754b940446404fb3e6a781a0824333"
          hideOptions
        />

        <div className={styles.stepsContainer}>
          <Step {...joinMissionStepPayload} />
          <Step {...tipsStepPayload} />
          <div className={styles.disclaimer}>
            <Button
              size="md"
              variant="ghost"
              onClick={dismissWelcomePageHandler}
            >
              Dismiss page
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(Welcome);
