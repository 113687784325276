import React, { ReactElement, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import logoImg from './logo.svg';
import keyIcon from './key.svg';
import ReactTooltip from 'react-tooltip';
import { createUseStyles } from 'react-jss';
import {
  AdminSearchLocation,
  ProfileLocation,
  RootLocation,
  SignOutLocation,
  MissionControlBase,
  DiscoveryLocation,
  MissionPageLocation,
  VettingDashboardLocation,
  GuideLocation,
  AdminTeamWorkMissionsLocation,
  NonVerifiedGuideLocation,
  TokensLocation,
  SignInLocation,
  TargeterLocation,
  AStoreLocation,
  CommunityLocation,
  InviteLocation,
  ArchitectsLocation,
  SetupLocation,
} from '@src/locations';
import InviteModal from '@src/components/Modal/InviteModal';
import { CurrentUserObject } from '@a_team/models/dist/UserObject';
import useToggle from '@src/hooks/useToggle';
import { useStores } from '@src/stores';
import guideIcon from './guide.svg';
import pointsIcon from './points.svg';
import aStoreIcon from './a-store.svg';
import communityIcon from './community.svg';
import architectsIcon from './architects.svg';
import discoveryIcon from './discover.svg';
import teamWorkIcon from './teamworkNew.svg';
import logoutIcon from './logout.svg';
import skillsIcon from './skills.svg';
import missionsIcon from './missions.svg';
import loginIcon from './login.svg';
import setupIcon from './setup-icon.svg';
import UserAvatar from '@src/components/UserAvatar';
import { Breakpoints } from '@ateams/components';
import { IS_PROD, IS_SANDBOX } from '@src/config';
import { Icon, IconType } from '@ateams/components';
import LinkContainer from '@src/layouts/Main/Navbar/LinkContainer';
import qs from 'query-string';
import Bell from '@src/components/Bell';
import { useGetAllNotifications } from '@src/rq/notifications';
import { observer } from 'mobx-react';

interface Props {
  className?: string;
  currentUser: CurrentUserObject | null;
  open?: boolean;
  toggleMenu?: () => void;
}

const useStyles = createUseStyles({
  root: {
    width: 300,
    height: 'calc(100% - 50px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    top: 50,
    left: (props: Props) => (props.open ? 0 : -300),
    background: 'rgba(123, 11, 233, 0.9)',
    backdropFilter: 'blur(10px)',
    transition: 'all 0.2s',
    zIndex: 999999,
    position: 'fixed',
    overflowY: 'auto',
  },
  envLabel: {
    position: 'absolute',
    background: 'red',
    left: 28,
    color: '#fff',
    fontSize: 12,
    fontFamily: 'monospace',
    fontWeight: 600,
    lineHeight: '1em',
    padding: 2,
    width: 60,
    textAlign: 'center',
    overflow: 'hidden',
  },
  logo: {
    cursor: 'pointer',
    borderRadius: '80px',
    display: 'none',
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '4px',
    paddingLeft: 18,
    '& > *': {
      minWidth: 0,
    },
  },
  linkLabel: {
    color: '#fff',
    display: 'block',
    fontSize: 15,
    paddingLeft: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    height: '40px',
    width: 'auto',
    borderRadius: '40px',
    cursor: 'pointer',
    overflow: 'visible',
    '& > img': {
      height: '100%',
    },
  },
  active: {
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), rgba(0, 0, 0, 0)',
    boxShadow: '0px 1px 2px rgba(232, 208, 255, 0.24)',
    borderRadius: 0,
  },
  userItems: {
    marginBottom: '28px',
    '&:last-of-type': {
      marginBottom: 28,
    },
  },
  missionThumbnail: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#FFFFFF',
    backgroundColor: '#08A5FE',
    flex: 'none',
  },
  mobileOnly: {
    display: 'block',
  },
  highlight: {
    display: 'none',
    height: '100%',
    width: '3px',
    backgroundColor: 'white',
    position: 'absolute',
    left: '4px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      width: 120,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      height: '100%',
      zIndex: '1002',
      background: '#6702CC',
      padding: '40px 0 40px',
      left: '0 !important',
      backdropFilter: 'unset',
      top: 0,
    },
    mobileOnly: {
      display: 'none',
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
    },
    linkLabel: {
      fontSize: 12,
      paddingLeft: 0,
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: -4,
      padding: 4,
    },
    icon: {
      overflow: 'hidden',
      width: 50,
    },
    active: {
      background: 'none',
      boxShadow: 'none',
      position: 'relative',
      '& span': {
        display: 'inline !important',
      },
    },
    userItems: {
      padding: 0,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
});

function Navbar(props: Props): ReactElement {
  const { className, currentUser } = props;
  const [inviteOpen, toggleInviteOpen] = useToggle();
  const styles = useStyles(props);
  const { auth, uiStore } = useStores();
  const location = useLocation();
  const { data } = useGetAllNotifications({
    onlyUnread: true,
  });

  useEffect(() => {
    const query = qs.parse(location.search);
    if (!auth.isActiveCompanyUser && currentUser && query.refer !== undefined) {
      toggleInviteOpen();
      props.toggleMenu && props.toggleMenu();
    }
  }, [auth.isActiveCompanyUser, currentUser, location.search]);

  const showSetup = useMemo(() => {
    return (
      auth.withOnboardingV2 &&
      (!auth.onboardingCompleted || auth?.user?.needsWelcomePage)
    );
  }, [
    auth.withOnboardingV2,
    auth.onboardingCompleted,
    auth?.user?.needsWelcomePage,
  ]);

  return (
    <div className={cx(styles.root, className, 'navbar')}>
      <ReactTooltip
        html={true}
        effect="solid"
        place="right"
        className="breadCrumbTooltip"
      />
      <div style={{ flexGrow: 1, width: '100%' }}>
        <Link
          className={styles.logo}
          to={
            auth.fullAccess
              ? RootLocation
              : ProfileLocation(currentUser?.username || '')
          }
        >
          <img src={logoImg} alt="logo" />
        </Link>
        {!IS_PROD && (
          <div className={styles.envLabel}>
            {IS_SANDBOX ? 'SANDBOX' : 'DEV'}
          </div>
        )}
        {!auth.isActiveCompanyUser && currentUser && (
          <div style={{ marginTop: '36px' }}>
            <InviteModal
              open={inviteOpen}
              onClose={toggleInviteOpen}
              currentUser={currentUser}
            />

            {showSetup && (
              <LinkContainer to={SetupLocation} showActive>
                <img src={setupIcon} alt={'setup'} />
                <div className={styles.linkLabel}>Setup</div>
              </LinkContainer>
            )}

            <div
              className={cx(styles.items)}
              data-testing-id="notification-slideout"
              style={{
                cursor: 'pointer',
                marginBottom: '28px',
                pointerEvents: uiStore.notificationSlider ? 'none' : undefined,
              }}
              onClick={uiStore.toggleNotificationSlider}
            >
              <Bell totalUnreadNotifications={data?.length ?? 0} />

              <div className={styles.linkLabel}>Notifications</div>
              <span className={styles.highlight} />
            </div>

            {(auth.fullAccess || showSetup) && (
              <LinkContainer to={DiscoveryLocation} showActive>
                <img src={discoveryIcon} alt={'discovery'} />
                <div className={styles.linkLabel}>Discovery</div>
              </LinkContainer>
            )}

            {(auth.basicAccess || !showSetup) && (
              <LinkContainer to={MissionControlBase} showActive>
                <img src={teamWorkIcon} alt={'teamwork'} />
                <div className={styles.linkLabel}>Missions</div>
              </LinkContainer>
            )}
            {auth.isAdmin && (
              <LinkContainer to={AdminTeamWorkMissionsLocation} showActive>
                <img src={missionsIcon} alt={'admin teamwork'} />
                <div className={styles.linkLabel}>Admin</div>
              </LinkContainer>
            )}
            {(currentUser.hasAccessToCommunity || auth.isAdmin) && (
              <LinkContainer to={CommunityLocation} showActive>
                <img src={communityIcon} alt={'Community'} />
                <div className={styles.linkLabel}>Community</div>
              </LinkContainer>
            )}
            {auth.isAdmin && (
              <LinkContainer to={ArchitectsLocation} showActive>
                <img src={architectsIcon} alt={'Architects'} />
                <div className={styles.linkLabel}>Architects</div>
              </LinkContainer>
            )}
            {auth.withInvitePage ? (
              <LinkContainer to={InviteLocation} showActive>
                <img src={keyIcon} alt={'Invite'} />
                <div className={styles.linkLabel}>Invite</div>
              </LinkContainer>
            ) : (
              <div
                className={cx(styles.items, inviteOpen && styles.active)}
                style={{
                  cursor: 'pointer',
                  marginBottom: '28px',
                }}
                onClick={() => {
                  toggleInviteOpen();
                  props.toggleMenu && props.toggleMenu();
                }}
              >
                <img src={keyIcon} alt={currentUser.fullName} />
                <div className={styles.linkLabel}>Invite</div>
                <span className={styles.highlight} />
              </div>
            )}
            {!showSetup && (
              <LinkContainer to={TokensLocation} showActive>
                <img src={pointsIcon} alt={'tokens'} style={{ margin: 8 }} />
                <div className={styles.linkLabel}>Tokens</div>
              </LinkContainer>
            )}

            {auth.fullAccess && auth.withAStore && (
              <LinkContainer to={AStoreLocation} showActive>
                <img src={aStoreIcon} alt={'A Store'} />
                <div className={styles.linkLabel}>A.Store</div>
              </LinkContainer>
            )}

            {(auth.basicAccess || showSetup) && (
              <LinkContainer
                to={auth.fullAccess ? GuideLocation : NonVerifiedGuideLocation}
                showActive
              >
                <img src={guideIcon} alt={'guide book'} />
                <div className={styles.linkLabel}>A.Guide</div>
              </LinkContainer>
            )}

            {(auth.isVetter || auth.isAdmin) && (
              <LinkContainer to={VettingDashboardLocation} showActive>
                <Icon
                  type={IconType.SelectionTeam}
                  size={'large'}
                  style={{ cursor: 'pointer', margin: '0 5px' }}
                />
                <div className={styles.linkLabel}>Evaluation</div>
              </LinkContainer>
            )}

            {auth.isAdmin && (
              <>
                <LinkContainer to={AdminSearchLocation} showActive>
                  <img src={discoveryIcon} alt="Admin Search" />
                  <div className={styles.linkLabel}>DB</div>
                </LinkContainer>
                <LinkContainer to={TargeterLocation} showActive>
                  <img
                    src={skillsIcon}
                    style={{ width: '40px', height: '40px' }}
                    alt="Skill Targeter"
                  />
                  <div className={styles.linkLabel}>Targeter</div>
                </LinkContainer>
              </>
            )}
          </div>
        )}
      </div>

      {currentUser ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '28px',
          }}
        >
          <>
            {currentUser.activeMissions &&
              currentUser.activeMissions.map((mission) => (
                <LinkContainer
                  to={MissionPageLocation(mission.mid)}
                  key={mission.mid}
                >
                  <div
                    className={styles.missionThumbnail}
                    style={{ backgroundColor: mission.thumbnailColor }}
                  >
                    {mission.title[0].toUpperCase()}
                  </div>
                  <div className={styles.mobileOnly}>
                    <div className={styles.linkLabel}>{mission.title}</div>
                  </div>
                </LinkContainer>
              ))}
            {auth.isActive && !auth.isActiveCompanyUser && (
              <LinkContainer
                to={ProfileLocation(currentUser.username)}
                className={styles.icon}
                data-tip="Profile"
              >
                <UserAvatar size={40} src={currentUser.profilePictureURL} />
                <div className={styles.linkLabel}>Profile</div>
              </LinkContainer>
            )}
          </>

          {auth.isActiveCompanyUser && (
            <Link to={SignOutLocation} data-tip="Sign out">
              <div className={cx(styles.icon, styles.userItems, styles.items)}>
                <img src={logoutIcon} alt="sign out" />
                <div className={styles.linkLabel}>Sign out</div>
              </div>
            </Link>
          )}
        </div>
      ) : (
        <Link to={SignInLocation} data-tip="Sign In">
          <div className={cx(styles.userItems, styles.items)}>
            <img src={loginIcon} alt="sign in" />
            <div className={styles.linkLabel}>Sign In</div>
          </div>
        </Link>
      )}
    </div>
  );
}

export default observer(Navbar);
