import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { SubmitPreVettingFormPayload } from '@ateams/api/dist/endpoints/vetting-process-pre-vetting';
import { BorderRadius, Button } from '@ateams/components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import SkillsSection from './SkillsSection';
import ProjectsSection from './ProjectsSection';
import AdditionalMaterialsSection from './AdditionalMaterialsSection';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import { useValidateURL } from '../use-validate-url';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { observer } from 'mobx-react-lite';
import {
  ExperienceUserMember,
  ExperienceMemberRole,
} from '@a_team/models/dist/ExperienceObject';
import { CollaboratorStatus, UserId } from '@a_team/models/dist/UserObject';
import { useGetProfileSuggestions } from '@src/rq/profileSuggestions';
import { useGetExperiencesCompletenessScoreByUserId } from '@src/rq/experiences';
import { ExperienceCompletenessScore } from '@src/rq/experiences';
import { ExistingProject, NewProject } from '@src/stores/Profile/models';

export const MIN_ALL_SELECTED_SKILLS = 2;
export const MAX_SELECTED_SKILLS = 5;
export const MIN_SELECTED_PROJECTS = 2;

export interface PreVettingFormProps {
  variant: VettingFormVariant;
  userId: UserId;
  firstName: string;
  onSubmit?: OnPreVettingFormSubmit;
}

export type OnPreVettingFormSubmit = (
  payload: SubmitPreVettingFormPayload,
) => unknown;

const useStyles = createUseStyles({
  container: {
    width: '600px',
    margin: '0 auto',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    justifyContent: 'flex-start',
    fontFamily: 'Inter',
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    color: '#222',
    lineHeight: '30px',
  },
  submitButton: {
    width: '172px',
    marginTop: '8px',
    marginBottom: '16px',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: BorderRadius.medium,
    alignSelf: 'flex-end',
  },
});

export const PreVettingForm = observer((props: PreVettingFormProps) => {
  const styles = useStyles();
  const { auth, users, vettingProcesses } = useStores();
  const { data: profileSuggestions } = useGetProfileSuggestions();
  const suggestedProjects = profileSuggestions?.projects?.projects || [];
  const { userId, firstName } = props;

  const [selectedSkillIds, setSelectedSkillIds] = useState<string[]>([]);
  const [requiredSkillIds, setRequiredSkillIds] = useState<string[]>([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
  const hasSelectedProjects = useRef(false);
  const [links, setLinks] = useState<string[]>(['']);
  const [validLinks, setValidLinks] = useState<boolean[]>([true]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [additionalMaterialDetails, setAdditionalMaterialDetails] =
    useState('');
  const [loading, setLoading] = useLoadingState();
  const { nonce } = useValidateURL();
  const {
    data: projectCompletenessScores,
    isLoading: isProjectCompletenessScoresLoading,
  } = useGetExperiencesCompletenessScoreByUserId({
    userId,
  });

  useEffect(() => {
    let isMounted = true;
    setLoading(
      (async () => {
        const { requiredSkills } = await vettingProcesses.getPreVettingFormData(
          nonce,
        );
        if (isMounted) {
          setRequiredSkillIds(requiredSkills.map((skill) => skill.id));
        }
      })(),
    );

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (auth.currentUser?.username) {
      users.fetchCurrentProfile(auth.currentUser.username);
    }
  }, [auth.currentUser?.username]);

  const profile = useMemo(() => {
    return users.profile;
  }, [users.profile]);

  const getProjectId = (project: ExistingProject | NewProject) =>
    'eid' in project ? project.eid : '_id' in project ? project._id : '';

  const projects = useMemo(() => {
    if (!profile) {
      return [];
    }

    let displayedProjects = profile?.displayedProjects || [];

    displayedProjects = displayedProjects.filter((project) => {
      return project.members.some(
        (member) =>
          (member as ExperienceUserMember).uid === profile.data.uid &&
          (member.experienceRole === ExperienceMemberRole.Owner ||
            member.collaboratorStatus === CollaboratorStatus.Active),
      );
    });

    const projects = [...(suggestedProjects || []), ...displayedProjects];

    const sortedProjects = projects.sort((a, b) => {
      const aScore =
        projectCompletenessScores?.find(
          (score: ExperienceCompletenessScore) => score.eid === getProjectId(a),
        )?.completenessScore || 0;
      const bScore =
        projectCompletenessScores?.find(
          (score: ExperienceCompletenessScore) => score.eid === getProjectId(b),
        )?.completenessScore || 0;

      if (bScore !== aScore) {
        return bScore - aScore;
      }

      // If completenessScore is the same, sort by startDate
      const aStartDate = a.startDate ? new Date(a.startDate).getTime() : 0;
      const bStartDate = b.startDate ? new Date(b.startDate).getTime() : 0;

      return bStartDate - aStartDate;
    });

    return sortedProjects;
  }, [
    suggestedProjects,
    projectCompletenessScores,
    profile?.displayedProjects,
    auth.currentUser?.username,
  ]);

  useEffect(() => {
    if (
      !loading &&
      !isProjectCompletenessScoresLoading &&
      !hasSelectedProjects.current
    ) {
      const completedProjects = projects.filter(
        (project) => !project.isMissingMinimumRequirements,
      );
      setSelectedProjectIds(completedProjects.slice(0, 2).map(getProjectId));
      hasSelectedProjects.current = true;
    }
  }, [loading, isProjectCompletenessScoresLoading, hasSelectedProjects]);

  const showSkillsError = useMemo(
    () =>
      selectedSkillIds.length + requiredSkillIds.length <
      MIN_ALL_SELECTED_SKILLS,
    [selectedSkillIds, requiredSkillIds],
  );

  const missingSkills = useMemo(() => {
    const selectedProjects = projects.filter((project) => {
      const id = getProjectId(project);
      return selectedProjectIds.includes(id);
    });

    const missingSkills = requiredSkillIds.filter(
      (skillId) =>
        !selectedProjects.some((project) =>
          project.skills?.some((skill) => skill === skillId),
        ),
    );

    return missingSkills;
  }, [selectedProjectIds, requiredSkillIds]);

  const showProjectsError = useMemo(() => {
    if (selectedProjectIds.length >= MIN_SELECTED_PROJECTS) {
      return missingSkills.length > 0;
    }

    return true;
  }, [selectedProjectIds]);

  const isVariantWithRequiredAdditionalMaterials = useMemo(() => {
    return (
      props.variant === VettingFormVariant.SoftwareEngineering ||
      props.variant === VettingFormVariant.Design
    );
  }, [props.variant]);

  const showAdditionalMaterialsError = useMemo(() => {
    const hasNonEmptyLinkOrFile =
      links.some((link) => link && link.length > 0) || uploadedFiles.length > 0;
    const allLinksValid = validLinks.every((link) => link);

    return (
      isVariantWithRequiredAdditionalMaterials &&
      (!hasNonEmptyLinkOrFile || !allLinksValid)
    );
  }, [links, uploadedFiles, validLinks]);

  const isValid = useMemo(() => {
    return (
      !showSkillsError && !showProjectsError && !showAdditionalMaterialsError
    );
  }, [
    showSkillsError,
    showProjectsError,
    validLinks,
    showAdditionalMaterialsError,
  ]);

  const onSubmit = () => {
    const nonEmptyLinks = links.filter((link) => link.length > 0);

    if (isValid) {
      props.onSubmit?.({
        variant: props.variant,
        selectedSkills: requiredSkillIds.concat(selectedSkillIds),
        selectedExperiences: selectedProjectIds,
        additionalMaterialLinks: nonEmptyLinks,
        additionalMaterialFiles: uploadedFiles,
        additionalMaterialDetails: additionalMaterialDetails,
      });
    }
  };

  return (
    <>
      <LoadingIndicator
        loading={loading || isProjectCompletenessScoresLoading}
      />
      <div className={styles.container}>
        <div className={styles.title}>
          Hi {firstName}, answer a few questions to guide and schedule our call
        </div>
        <SkillsSection
          selectedSkillIds={selectedSkillIds}
          setSelectedSkillIds={setSelectedSkillIds}
          requiredSkillIds={requiredSkillIds}
          showSkillsError={showSkillsError}
        />
        <ProjectsSection
          profile={profile}
          projects={projects}
          selectedProjectIds={selectedProjectIds}
          setSelectedProjectIds={setSelectedProjectIds}
          requiredSkillIds={requiredSkillIds}
          showProjectsError={showProjectsError}
        />
        <AdditionalMaterialsSection
          showAdditionalMaterialsError={showAdditionalMaterialsError}
          links={links}
          setLinks={setLinks}
          validLinks={validLinks}
          setValidLinks={setValidLinks}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          additionalMaterialDetails={additionalMaterialDetails}
          setAdditionalMaterialDetails={setAdditionalMaterialDetails}
        />
        <Button
          className={styles.submitButton}
          size={'small'}
          onClick={onSubmit}
          data-testing-id={'pre-vetting-form-submit-button'}
          disabled={!isValid}
        >
          Submit & Schedule
        </Button>
      </div>
    </>
  );
});
