import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { OnboardingStage, UserStatus } from '@a_team/models/dist/UserObject';
import { Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { RootLocation } from '@src/locations';
import MainLayout from '@src/layouts/Main';
import Header from './Header';
import CompleteProfileStep from './Steps/CompleteProfileStep';
import EvaluationCallStep from './Steps/EvaluationCallStep';
import JoinTheTeamStep from './Steps/JoinTheTeamStep';
import ReviewProfileStep from './Steps/ReviewProfileStep';
import ReviseProfileStep from './Steps/ReviseProfileStep';
import Welcome from './Welcome';
import ThankYouEvaluationCall from './ThankYouEvaluationCall';
import YouAreIn from './YouAreIn';
import LimitedMissionsModal from '@src/components/Modal/LimitedMissions';
import StepSkeleton from './Steps/common/Step/StepSkeleton';

const useStyles = createUseStyles<{ isLimited: boolean }>({
  container: {
    maxWidth: 1240,
    margin: '0 auto',
    padding: '16px 0',
  },
  stepsContainer: {
    margin: '40px auto 0',
    maxWidth: 680,
    display: 'flex',
    gap: 40,
    flexDirection: 'column',
  },

  content: {
    opacity: ({ isLimited }: { isLimited: boolean }) => (isLimited ? 0.04 : 1),
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stepsContainer: {
      gap: 8,
      marginBottom: 40,
    },
  },
});

const REFRESH_TOKEN_INTERVAL_MS = 30000; // 30 seconds

const Setup = () => {
  const { auth } = useStores();
  const isLimited = auth.userStatus === UserStatus.ActiveWaitlisted;
  const styles = useStyles({
    isLimited,
  });

  const [onboardingStage, setOnboardingStage] = useState<OnboardingStage>(
    auth.onboardingStage ?? OnboardingStage.IncompleteProfile,
  );

  useEffect(() => {
    setOnboardingStage(
      auth.onboardingStage ?? OnboardingStage.IncompleteProfile,
    );

    let intervalId: number | null = null;

    if (
      [
        OnboardingStage.InterviewScheduled,
        OnboardingStage.Accepted,
        OnboardingStage.Completed,
      ].includes(onboardingStage) &&
      typeof window !== 'undefined'
    ) {
      intervalId = window.setInterval(() => {
        auth.renewToken();
      }, REFRESH_TOKEN_INTERVAL_MS);
    }

    return () => {
      if (intervalId !== null && typeof window !== 'undefined') {
        window.clearInterval(intervalId);
      }
    };
  }, [auth.onboardingStage, onboardingStage, auth]);

  if (auth.withOnboardingV2 === false) {
    return <Redirect to={RootLocation} />;
  }

  const isYouAreIn = onboardingStage === OnboardingStage.Accepted;
  const isWelcome = onboardingStage === OnboardingStage.Completed;

  if (isYouAreIn) return <YouAreIn />;

  if (auth.limitedAccess) {
    return <ThankYouEvaluationCall />;
  } else if (isWelcome) {
    return <Welcome />;
  }

  return (
    <MainLayout
      title="Setup Page"
      style={{ padding: '15px 24px' }}
      contentClassName={styles.content}
    >
      {isLimited && <LimitedMissionsModal open={isLimited} />}
      <div className={styles.container}>
        <Header
          title="Getting started as an A.Team Builder"
          subtitle="Complete the steps below to request to join A.Team, a network of world-class builders pursuing their craft with autonomy."
        />

        <div className={styles.stepsContainer}>
          {auth.onboardingStage === undefined ? (
            <>
              <StepSkeleton withCta withMedia isCurrentStep />
              <StepSkeleton />
              <StepSkeleton />
              <StepSkeleton isFinalStep />
            </>
          ) : (
            <>
              <CompleteProfileStep onboardingStage={onboardingStage} />
              <ReviewProfileStep onboardingStage={onboardingStage} />
              <ReviseProfileStep onboardingStage={onboardingStage} />
              <EvaluationCallStep onboardingStage={onboardingStage} />
              <JoinTheTeamStep />
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(Setup);
