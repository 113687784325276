import { Breakpoints } from '@ateams/components';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Step, { StepStatus } from '../Steps/common/Step';
import StepIcon, { StepIconType } from '../Steps/common/Step/StepIcon';
import MainLayout from '@src/layouts/Main';
import Header from './../Header';

export const useStyles = createUseStyles({
  container: {
    maxWidth: 1240,
    margin: '0 auto',
    padding: '16px 0',
  },
  stepsContainer: {
    margin: '40px auto 0',
    maxWidth: 680,
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
  },
  disclaimer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: '#818388',
    gap: 16,
    fontSize: 15,
    marginTop: 28,
    '& button': {
      width: 138,
      height: 44,
      fontSize: 14,
      borderColor: '#DADADC !important',
    },
  },
  defaultStep: {
    backgroundColor: '#F9FAFC',
    padding: 16,
    borderRadius: 16,
    marginBottom: 8,
  },
  link: {
    color: '#6D00D7',
    fontSize: 15,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 20,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 400,
    color: '#62646A',
    marginTop: 8,
    marginBottom: 16,
    textAlign: 'center',
    lineHeight: '24px',
  },
  thankYouEvaluationCallHeader: {
    '& h1': {
      maxWidth: 250,
      margin: '0 auto',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stepsContainer: {
      gap: 8,
      marginBottom: 40,
    },
    disclaimer: {
      flexDirection: 'row',
    },
    title: {
      marginTop: 50,
    },
    thankYouEvaluationCallHeader: {
      '& h1': {
        maxWidth: '100%',
      },
    },
  },
});
const ThankYouEvaluationCall = () => {
  const styles = useStyles();

  const curatedMissionsStepPayload = {
    stepIcon: <StepIcon type={StepIconType.COMPASS} />,
    title: 'Curated missions',
    description:
      'To increase your chances of success, we’ll email you relevant missions that match your expertise.',
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  const gettingStartedStepPayload = {
    stepIcon: <StepIcon type={StepIconType.GETTING_STARTED} />,
    title: 'Getting started',
    description:
      'Due to limited missions for your specific skillset, you may receive fewer opportunities until you join your first mission.',
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  const getNoticedStepPayload = {
    stepIcon: <StepIcon type={StepIconType.PROFILE} />,
    title: 'Get noticed',
    description:
      'To increase your chances of being invited to apply, highlight your skills and experience in detail on your profile.',
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  const fullAccessStepPayload = {
    stepIcon: <StepIcon type={StepIconType.STAR} />,
    title: 'Full access',
    description:
      'After you join your first mission, you’ll be granted full access to the platform and be able to apply to any mission.',
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  return (
    <MainLayout
      title="Thanks for joining the evaluation call"
      style={{ padding: '15px 24px' }}
    >
      <div className={styles.container}>
        <Header
          title="Thanks for joining the evaluation call"
          subtitle="Your profile has been moved to personalized mission access."
          videoUrl="https://www.loom.com/share/ee754b940446404fb3e6a781a0824333"
          hideOptions
          className={styles.thankYouEvaluationCallHeader}
        />

        <div className={styles.stepsContainer}>
          <div>
            <div className={styles.title}>Personalized mission access</div>
            <div className={styles.subtitle}>
              After reviewing your skills and experience, our evaluation team
              found that there are limited missions available for builders with
              your skillset and background.
            </div>

            <Step {...curatedMissionsStepPayload} />
            <Step {...gettingStartedStepPayload} />
            <Step {...getNoticedStepPayload} />
            <Step {...fullAccessStepPayload} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(ThankYouEvaluationCall);
