import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { Card } from '../../components/card';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';
import { InputLabel, Text } from '../../components/typography';
import { ValueTag } from '../../components/value-tag';
import { UserTalentSkillAssignment } from '@a_team/models/dist/TalentCategories';
import { LinkIcon } from '../../components/icons/link';
import { InboxIcon } from '../../components/icons/inbox';
import { CalendlyButton } from '../../components/calendly-button';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { TextInput } from '../../components/text-input';
import { GOOGLE_PLACES_API_KEY } from '@src/config';
import {
  OnWorksElseWhereChange,
  WorksElseWhereInput,
} from './works-else-where';
import { AvailabilityInput, OnAvailabilityChange } from './availability';
import { OnWorkingHoursChange, WorkingHoursInput } from './working-hours';
import {
  ESTHoursOverlapInput,
  OnESTHoursOverlapChange,
} from './est-hours-overlap';
import { Separator } from '../../components/separator';
import {
  AdditionalMaterialsFiles,
  OnDeleteAdditionalMaterial,
  OnUploadAdditionalMaterial,
} from './additional-materials-files';
import {
  LocationAutocompleteV2,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import {
  formatLocation,
  parseLocation,
} from '@src/components/LocationAutocompleteV2/utils';
import {
  TranscriptLocation,
  VettingDashboardRecordingLocation,
} from '@src/locations';

const useStyles = createUseStyles({
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '32px',
    marginBottom: Spacing.medium,
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  rowLabel: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0, // Prevents the label from being squashed by the other elements
    alignItems: 'center',
    width: '268px',
    marginRight: Spacing.large,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.colors.Grey[800],
  },
  rowValues: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    flexWrap: 'wrap',
    minWidth: '480px',
  },
  techStackSkillsContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
  },
  purpleTag: {
    background: theme.colors.Hannibal[100],
    color: theme.colors.Hannibal[600],
  },
  emailTag: {
    cursor: 'pointer',
  },
  locationAutocomplete: {
    minWidth: '130px',
  },
  linkEllipsis: {
    maxWidth: '500px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export interface VettingFeedbackFormBuilderInfo {
  position?: RoleCategoryObject;
  techStack: UserTalentSkillAssignment[];
  location?: VettingFeedbackFormDefaults['answers']['location'];
  worksElseWhere?: VettingFeedbackFormDefaults['answers']['worksElseWhere'];
  availability?: VettingFeedbackFormDefaults['answers']['availability'];
  recordingDownloadLinks?: VettingFeedbackFormDefaults['general']['vettingProcess']['recordingDownloadLinks'];
  transcriptJobIds?: VettingFeedbackFormDefaults['general']['vettingProcess']['transcriptJobIds'];
  additionalMaterialsFiles?: VettingFeedbackFormDefaults['answers']['additionalMaterialsFiles'];
  additionalMaterials?: VettingFeedbackFormDefaults['answers']['additionalMaterials'];
  additionalMaterialLinks?: VettingFeedbackFormDefaults['general']['vettingProcess']['additionalMaterialLinks'];
  additionalMaterialFileUrls?: VettingFeedbackFormDefaults['general']['vettingProcess']['additionalMaterialFiles'];
  additionalMaterialDetails?: VettingFeedbackFormDefaults['general']['vettingProcess']['additionalMaterialDetails'];
  codeSample?: string;
  linkedIn?: string;
  website?: string;
  email: string;
  calendarUrl?: string;
}

export type OnVettingFeedbackFormBuilderInfoChange = (
  data: VettingFeedbackFormBuilderInfo,
) => void;

type BaseVettingFeedbackFormBuilderInfoProps = {
  defaultValues: VettingFeedbackFormBuilderInfo;
  onChange?: OnVettingFeedbackFormBuilderInfoChange;
  className?: string;
};

type ReadOnlyVettingFeedbackFormBuilderInfoProps = {
  isReadOnly: true;
};

type EditableVettingFeedbackFormBuilderInfoProps = {
  isReadOnly?: false;
  onUploadAdditionalMaterial: OnUploadAdditionalMaterial;
  onDeleteAdditionalMaterial: OnDeleteAdditionalMaterial;
};

type VettingFeedbackFormBuilderInfoProps =
  BaseVettingFeedbackFormBuilderInfoProps &
    (
      | ReadOnlyVettingFeedbackFormBuilderInfoProps
      | EditableVettingFeedbackFormBuilderInfoProps
    );

export const VettingFeedbackFormBuilderInfoForm: React.FC<VettingFeedbackFormBuilderInfoProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [location, setLocation] = useState(props.defaultValues.location);
    const [worksElseWhere, setWorksElseWhere] = useState(
      props.defaultValues.worksElseWhere,
    );
    const [availability, setAvailability] = useState(
      props.defaultValues.availability?.availability,
    );
    const [workingHours, setWorkingHours] = useState(
      props.defaultValues.availability?.workingHours,
    );
    const [hoursOfOverlapWithEST, setHoursOfOverlapWithEST] = useState(
      props.defaultValues.availability?.hoursOfOverlapWithEST,
    );
    const [note, setNote] = useState(props.defaultValues.availability?.note);
    const [additionalMaterialsFiles, setAdditionalMaterialsFiles] = useState(
      props.defaultValues.additionalMaterialsFiles || [],
    );
    const [additionalMaterials, setAdditionalMaterials] = useState(
      props.defaultValues.additionalMaterials,
    );
    const [loading, setLoading] = useLoadingState();

    const recordingPageUrls = useMemo(() => {
      if (!props.defaultValues.recordingDownloadLinks) return [];
      const urls = props.defaultValues.recordingDownloadLinks.filter(
        (url) => url,
      );
      const recordingPageUrls = urls.map(
        (url) =>
          `${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
            url,
          )}`,
      );
      return recordingPageUrls;
    }, []);

    const onChange = (data: Partial<VettingFeedbackFormBuilderInfo>) => {
      props.onChange?.({
        ...props.defaultValues,
        location: data.location || location,
        worksElseWhere:
          'worksElseWhere' in data ? data.worksElseWhere : worksElseWhere,
        availability: {
          availability:
            'availability' in (data.availability || {})
              ? data.availability?.availability
              : availability,
          workingHours: {
            startTime:
              data.availability?.workingHours?.startTime ||
              workingHours?.startTime,
            endTime:
              data.availability?.workingHours?.endTime || workingHours?.endTime,
          },
          hoursOfOverlapWithEST:
            data.availability && 'hoursOfOverlapWithEST' in data.availability
              ? data.availability?.hoursOfOverlapWithEST
              : hoursOfOverlapWithEST,
          note: data.availability?.note || note,
        },
        additionalMaterialsFiles:
          'additionalMaterialsFiles' in data
            ? data.additionalMaterialsFiles
            : props.defaultValues.additionalMaterialsFiles,
        additionalMaterials:
          'additionalMaterials' in data
            ? data.additionalMaterials
            : additionalMaterials,
      });
    };

    const onLocationChange: OnLocationChange = (options) => {
      if (!options) return;
      const option = Array.isArray(options) ? options[0] : options;
      const { prediction, countryShortName } = option;

      if (prediction?.structured_formatting) {
        const location = parseLocation(
          prediction.structured_formatting,
          countryShortName,
        );

        setLocation(location);
        onChange({ location });
      }
    };

    const onWorksElseWhereChange: OnWorksElseWhereChange = (worksElseWhere) => {
      setWorksElseWhere(worksElseWhere);
      onChange({ worksElseWhere });
    };

    const onAvailabilityChange: OnAvailabilityChange = (availability) => {
      setAvailability(availability);
      onChange({ availability: { availability } });
    };

    const onWorkingHoursChange: OnWorkingHoursChange = (workingHours) => {
      setWorkingHours(workingHours);
      onChange({ availability: { workingHours } });
    };

    const onHoursOfOverlapWithESTChange: OnESTHoursOverlapChange = (
      hoursOfOverlapWithEST,
    ) => {
      setHoursOfOverlapWithEST(hoursOfOverlapWithEST);
      onChange({
        availability: { hoursOfOverlapWithEST },
      });
    };

    const onNoteChange = (note: string) => {
      setNote(note);
      onChange({ availability: { note } });
    };

    const onAdditionalMaterialFileUpload: OnUploadAdditionalMaterial = async (
      file,
    ) => {
      if (!('onUploadAdditionalMaterial' in props)) {
        throw new Error('Something went wrong 🤪');
      }
      const newAdditionalMaterial = await props.onUploadAdditionalMaterial(
        file,
      );
      setAdditionalMaterialsFiles((additionalMaterialsFiles) => {
        const newAdditionalMaterialsFiles = [
          ...additionalMaterialsFiles,
          newAdditionalMaterial,
        ];
        onChange({ additionalMaterialsFiles: newAdditionalMaterialsFiles });
        return newAdditionalMaterialsFiles;
      });
      return newAdditionalMaterial;
    };

    const onAdditionalMaterialFileDelete: OnDeleteAdditionalMaterial = async (
      additionalMaterialId,
    ) => {
      if (!('onDeleteAdditionalMaterial' in props)) {
        throw new Error('Something went wrong 🤪');
      }
      await props.onDeleteAdditionalMaterial(additionalMaterialId);
      setAdditionalMaterialsFiles((additionalMaterialsFiles) => {
        const newAdditionalMaterialsFiles = additionalMaterialsFiles.filter(
          (additionalMaterial) =>
            additionalMaterial.id !== additionalMaterialId,
        );
        onChange({ additionalMaterialsFiles: newAdditionalMaterialsFiles });
        return newAdditionalMaterialsFiles;
      });
      return additionalMaterialId;
    };

    const onAdditionalMaterialsChange = (additionalMaterials: string) => {
      setAdditionalMaterials(additionalMaterials);
      onChange({ additionalMaterials });
    };

    const onClickOnEmail = () => {
      copy(props.defaultValues.email);
      setLoading(Promise.resolve(new Date()), 'Email copied to clipboard');
    };

    return (
      <Card title={'About the builder'} className={props.className}>
        {props.defaultValues.position && (
          <div className={styles.inputRow}>
            <div className={styles.rowLabel}>Main role</div>
            <div className={styles.rowValues}>
              <ValueTag>{props.defaultValues.position.title}</ValueTag>
            </div>
          </div>
        )}

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>{'Skills (based on profile)'}</div>
          <div
            className={cx(styles.rowValues, styles.techStackSkillsContainer)}
          >
            {props.defaultValues.techStack.map((skill) => (
              <ValueTag
                key={skill.talentSkillId}
              >{`${skill.talentSkillName}  ${skill.rating}`}</ValueTag>
            ))}
          </div>
        </div>

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Location</div>
          <div className={styles.rowValues}>
            <LocationAutocompleteV2
              openMenuOnClick={false}
              defaultInputValue={
                location ? formatLocation(location) : undefined
              }
              apiKey={GOOGLE_PLACES_API_KEY}
              autocompleteOptions={{ types: ['(cities)'] }}
              onChange={onLocationChange}
              className={styles.locationAutocomplete}
              color={location ? theme.colors.Grey[200] : undefined}
              isReadOnly={isReadOnly}
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>
            Does this builder work elsewhere?
          </div>
          <WorksElseWhereInput
            value={worksElseWhere}
            onChange={onWorksElseWhereChange}
            isReadOnly={isReadOnly}
            className={styles.rowValues}
          />
        </div>

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Availability</div>
          <AvailabilityInput
            value={availability}
            onChange={onAvailabilityChange}
            isReadOnly={isReadOnly}
            className={styles.rowValues}
          />
        </div>

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>
            When is this builder available to work?
          </div>
          <WorkingHoursInput
            value={workingHours}
            onChange={onWorkingHoursChange}
            isReadOnly={isReadOnly}
            className={styles.rowValues}
          />
        </div>

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Hours of overlap with EST</div>
          <ESTHoursOverlapInput
            value={hoursOfOverlapWithEST}
            onChange={onHoursOfOverlapWithESTChange}
            isReadOnly={isReadOnly}
            className={styles.rowValues}
          />
        </div>

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Note on availability</div>
          <div className={styles.rowValues}>
            <TextInput
              value={note}
              onChange={(e) => onNoteChange(e.target.value)}
              placeholder={'Add a note...'}
              isReadOnly={isReadOnly}
              withLine={!isReadOnly}
            />
          </div>
        </div>

        <Separator direction={'horizontal'} space={Spacing.medium} />

        {props.defaultValues.codeSample && (
          <div className={styles.inputRow}>
            <div className={styles.rowLabel}>Sample Code</div>
            <div className={styles.rowValues}>
              <a
                href={props.defaultValues.codeSample}
                target="_blank"
                rel="noreferrer"
              >
                <ValueTag
                  className={styles.purpleTag}
                  startAdornment={<LinkIcon />}
                >
                  {props.defaultValues.codeSample}
                </ValueTag>
              </a>
            </div>
          </div>
        )}

        {props.defaultValues.additionalMaterialFileUrls &&
          props.defaultValues.additionalMaterialFileUrls.length > 0 && (
            <div className={styles.inputRow}>
              <div className={styles.rowLabel}>Additional Materials Files</div>
              <div className={styles.rowValues}>
                {props.defaultValues.additionalMaterialFileUrls.map(
                  (additionalMaterialFileUrl) => (
                    <a
                      key={additionalMaterialFileUrl}
                      href={additionalMaterialFileUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ValueTag
                        className={styles.purpleTag}
                        startAdornment={<LinkIcon />}
                      >
                        <div
                          className={styles.linkEllipsis}
                          title={additionalMaterialFileUrl}
                        >
                          {additionalMaterialFileUrl}
                        </div>
                      </ValueTag>
                    </a>
                  ),
                )}
              </div>
            </div>
          )}

        {props.defaultValues.additionalMaterialLinks &&
          props.defaultValues.additionalMaterialLinks.length > 0 && (
            <div className={styles.inputRow}>
              <div className={styles.rowLabel}>Additional Materials Links</div>
              <div className={styles.rowValues}>
                {props.defaultValues.additionalMaterialLinks.map(
                  (additionalMaterialLink) => (
                    <a
                      key={additionalMaterialLink}
                      href={additionalMaterialLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ValueTag
                        className={styles.purpleTag}
                        startAdornment={<LinkIcon />}
                      >
                        <div
                          className={styles.linkEllipsis}
                          title={additionalMaterialLink}
                        >
                          {additionalMaterialLink}
                        </div>
                      </ValueTag>
                    </a>
                  ),
                )}
              </div>
            </div>
          )}

        {props.defaultValues.additionalMaterialDetails && (
          <div className={styles.inputRow}>
            <div className={styles.rowLabel}>Additional Materials Details</div>
            <div>{props.defaultValues.additionalMaterialDetails}</div>
          </div>
        )}

        {props.defaultValues.linkedIn && (
          <div className={styles.inputRow}>
            <div className={styles.rowLabel}>Linkedin</div>
            <div className={styles.rowValues}>
              <a
                href={props.defaultValues.linkedIn}
                target="_blank"
                rel="noreferrer"
              >
                <ValueTag
                  className={styles.purpleTag}
                  startAdornment={<LinkIcon />}
                >
                  {props.defaultValues.linkedIn}
                </ValueTag>
              </a>
            </div>
          </div>
        )}

        {props.defaultValues.website && (
          <div className={styles.inputRow}>
            <div className={styles.rowLabel}>Website</div>
            <div className={styles.rowValues}>
              <a
                href={props.defaultValues.website}
                target="_blank"
                rel="noreferrer"
              >
                <ValueTag
                  className={styles.purpleTag}
                  startAdornment={<LinkIcon />}
                >
                  {props.defaultValues.website}
                </ValueTag>
              </a>
            </div>
          </div>
        )}

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Email address</div>
          <div className={styles.rowValues}>
            <ValueTag
              className={cx(styles.purpleTag, styles.emailTag)}
              startAdornment={<InboxIcon />}
              onClick={onClickOnEmail}
            >
              {props.defaultValues.email}
            </ValueTag>
          </div>
        </div>

        <Separator direction={'horizontal'} space={Spacing.medium} />

        {props.defaultValues.calendarUrl && (
          <>
            <div className={styles.inputRow}>
              <div className={styles.rowLabel}>Calendly Link</div>
              <div className={styles.rowValues}>
                <a
                  href={props.defaultValues.calendarUrl}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <CalendlyButton />
                </a>
              </div>
            </div>
          </>
        )}

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Call recording</div>
          <div className={styles.rowValues}>
            {recordingPageUrls?.map((recordingPageUrl, idx) => (
              <a
                key={recordingPageUrl}
                href={recordingPageUrl}
                target="_blank"
                rel="noreferrer"
              >
                <ValueTag
                  className={styles.purpleTag}
                  startAdornment={<LinkIcon />}
                >
                  Recording link {idx + 1}
                </ValueTag>
              </a>
            ))}
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.rowLabel}>Transcripts</div>
          <div className={styles.rowValues}>
            {props.defaultValues.transcriptJobIds?.map((jobId, idx) => {
              const transcriptUrl = `${TranscriptLocation(jobId)}`;
              return (
                <a
                  key={jobId}
                  href={transcriptUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ValueTag
                    className={styles.purpleTag}
                    startAdornment={<LinkIcon />}
                  >
                    Transcript link {idx + 1}
                  </ValueTag>
                </a>
              );
            })}
          </div>
        </div>

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <AdditionalMaterialsFiles
          className={styles.marginBottom}
          additionalMaterials={additionalMaterialsFiles}
          onUploadAdditionalMaterial={onAdditionalMaterialFileUpload}
          onDeleteAdditionalMaterial={onAdditionalMaterialFileDelete}
          isReadOnly={isReadOnly}
        />

        <InputLabel className={styles.marginBottom}>
          Note on additional materials
        </InputLabel>
        {isReadOnly ? (
          <Text isReadOnly readOnlyText={'Nothing was added.'}>
            {additionalMaterials}
          </Text>
        ) : (
          <div className={styles.marginLeft}>
            <TextInput
              value={additionalMaterials}
              onChange={(e) => onAdditionalMaterialsChange(e.target.value)}
              placeholder={'Ex. portfolio available in the PDF document...'}
              withLine={!isReadOnly}
              isReadOnly={isReadOnly}
            />
          </div>
        )}

        <LoadingIndicator key={new Date().toISOString()} loading={loading} />
      </Card>
    );
  });
