import React from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step/';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { useStores } from '@src/stores';
import { useHistory } from 'react-router-dom';

interface CompleteProfileStepProps {
  onboardingStage: OnboardingStage;
}

const CompleteProfileStep = ({ onboardingStage }: CompleteProfileStepProps) => {
  const { auth } = useStores();
  const history = useHistory();

  const handleOnClickCTA = () => {
    history.push(`/${auth.user?.username}`);
  };

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value="1" />,
    title: 'Build a stellar profile to stand out',
    description:
      'Ensure your profile fully represents your skills and experience. To submit for review, complete all of the requirements at the top of the profile page.',
    completionTime: 'Estimated completion time: 15 to 60 minutes',
    cta: (
      <Button size="md" onClick={handleOnClickCTA}>
        Complete profile
      </Button>
    ),
    videoUrl: 'https://www.loom.com/share/ee754b940446404fb3e6a781a0824333',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const COMPLETED_PROFILE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Build a stellar profile to stand out',
    description:
      "You've completed all of the requirements at the top of the profile page.",
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const stepPayload: SetupProps =
    onboardingStage === OnboardingStage.IncompleteProfile
      ? INCOMPLETE_DATA
      : COMPLETED_PROFILE_DATA;

  return <Step {...stepPayload} />;
};

export default observer(CompleteProfileStep);
