import { Breakpoints } from '@ateams/components';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import bookIcon from './icons/book.svg';
import chatIcon from './icons/chat.svg';
import profileIcon from './icons/profile.svg';
import closeIcon from './icons/close.svg';
import { UPLOADCARE_ASSETS_BASE_URL } from '@src/config';
import cx from 'classnames';
import { useScreenClass } from 'react-grid-system';

interface HeaderProps {
  title: string;
  subtitle: string;
  hideOptions?: boolean;
  videoUrl?: string;
  className?: string;
}

export const useStyles = createUseStyles<{ hasVideo?: boolean }>({
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 1240,
    margin: '0 auto',
    paddingBottom: 40,
    top: 0,
    left: 0,
    background: '#FFFFFF',
    zIndex: 9999,
    overflow: 'visible',
    padding: 24,
    backgroundImage: `url("${UPLOADCARE_ASSETS_BASE_URL}/99e49980-da83-426d-bdb4-fa3ca66b789d/")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '0 0 24px 24px',
  },
  marginDesktop: {
    marginBottom: ({ hasVideo }) => (hasVideo ? 290 : 0),
  },
  marginMobile: {
    marginBottom: ({ hasVideo }) => (hasVideo ? 290 : 230),
  },
  content: {
    position: 'relative',
    zIndex: 1,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    margin: 0,
    fontWeight: 600,
  },
  subtitle: {
    marginTop: 8,
    fontSize: 15,
    textAlign: 'center',
    color: '#818388',
    maxWidth: 550,
    margin: '0 auto',
  },
  buttonsWrapper: {
    marginTop: 40,
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    width: '100vw',
    marginLeft: -24,
    paddingLeft: 24,
    gap: 8,
    overflow: 'scroll',
    '& > button': {
      padding: 16,
      borderColor: '#DADADC !important',
      background: '#FFFFFF !important',
      borderRadius: 16,
    },
    '& > button span': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      fontSize: 14,
      fontWeight: 500,
    },
    '& > button:last-child': {
      marginRight: 24,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 999,
  },
  media: {
    marginTop: 30,
    marginBottom: 100,
  },
  placeholderImage: {
    position: 'absolute',
    maxWidth: 680,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 16,
  },
  buttonLink: {
    borderRadius: 16,
    padding: 16,
    border: '1px solid #DADADC',
    color: '#222222',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: 14,
    lineHeight: '19px',
    backgroundColor: '#FFFFFF',
    fontWeight: 500,
    '&:hover': {
      opacity: 0.8,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1240,
      margin: '0 auto',
      paddingBottom: 40,
      padding: 0,
      backgroundImage: `url("${UPLOADCARE_ASSETS_BASE_URL}a3fc9e45-e3c9-4fbc-b8f4-a04933869c72/")`,
      borderRadius: '0 0 40px 40px',
    },
    buttonsWrapper: {
      marginTop: 40,
      display: 'flex',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      justifyContent: 'center',
      marginLeft: 0,
      paddingLeft: 0,
      width: '100%',
      '& > button:last-child': {
        marginRight: 0,
      },
    },
    media: {
      marginTop: 40,
      marginBottom: 80,
    },
    mobileMargin: {
      marginBottom: ({ hasVideo }) => (hasVideo ? 330 : 0),
    },
    closeIcon: {
      display: 'none',
    },
  },
});

function Header({
  title,
  subtitle,
  hideOptions,
  videoUrl,
  className,
}: HeaderProps) {
  const styles = useStyles({ hasVideo: !!videoUrl });

  const screenClass = useScreenClass();
  const [hideHeader, setHideHeader] = useState(false);

  if (hideHeader) {
    return null;
  }

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <>
      <div className={cx(styles.container, className)}>
        <img
          src={closeIcon}
          alt="Close"
          className={styles.closeIcon}
          onClick={() => setHideHeader(true)}
        />
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.subtitle}>{subtitle}</div>
          {videoUrl && (
            <div className={styles.media}>
              <img
                className={styles.placeholderImage}
                width="100%"
                src="http://via.placeholder.com/1600x900?text=Placeholder"
                alt="Placeholder"
              />
            </div>
          )}
          {!hideOptions && (
            <div className={styles.buttonsWrapper}>
              <a
                className={styles.buttonLink}
                target="_blank"
                href="https://guide.a.team/getting-started"
                rel="noreferrer"
              >
                <img src={bookIcon} alt="Book" width={16} height={16} /> How to
                get started
              </a>
              <a
                className={styles.buttonLink}
                target="_blank"
                href="https://guide.a.team/getting-started/building-a-stellar-profile"
                rel="noreferrer"
              >
                <img src={profileIcon} alt="Profile" width={16} height={16} />
                Building a stellar profile
              </a>
              <a
                className={styles.buttonLink}
                target="_blank"
                href="https://guide.a.team/getting-started/welcome-chat"
                rel="noreferrer"
              >
                <img src={chatIcon} alt="Chat" width={16} height={16} />
                Join a welcome chat
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className={isMobile ? styles.marginMobile : styles.marginDesktop}
      ></div>
    </>
  );
}

export default Header;
